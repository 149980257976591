import StrongTextParser from "@/shared/components/StrongTextParser";
import Image from "next/image";
import styles from "./styles/ProcessSteps.module.scss";
import { ProcessStepsProps } from "./types";

const ProcessSteps = ({ steps }: ProcessStepsProps) => {
  return (
    <ul className={styles.list}>
      {steps.map(({ text, iconSrc }) => (
        <li key={text} className={styles.item}>
          <div className={styles.iconWrapper}>
            <Image
              src={iconSrc}
              alt={text}
              className={styles.icon}
              loading="lazy"
              width={45}
              height={35}
            />
          </div>
          <span>
            <StrongTextParser text={text} />
          </span>
        </li>
      ))}
    </ul>
  );
};

export default ProcessSteps;
