import { useTranslation } from 'next-i18next'
import mailSrc from '/public/icons/mail.svg'
import growthSrc from '/public/icons/growth.svg'
import handshakeSrc from '/public/icons/handshake.svg'
import phoneSrc from '/public/icons/phone.svg'
import ProcessSteps from './ProcessSteps'
import styles from './styles/ContactProcess.module.scss'
import StrongTextParser from '@/shared/components/StrongTextParser'
import Container from '@/shared/components/Container'

const ContactProcess = () => {
  const { t } = useTranslation('home')

  return (
    <section className={styles.contactProcess}>
      <Container>
        <div className={styles.inner}>
          <h2 className={styles.title}>
            <StrongTextParser text={t('contactProcess.title')} />
          </h2>

          <ProcessSteps steps={[
            { text: t('contactProcess.processSteps.step1') as string, iconSrc: mailSrc },
            { text: t('contactProcess.processSteps.step2') as string, iconSrc: phoneSrc },
            { text: t('contactProcess.processSteps.step3') as string, iconSrc: handshakeSrc },
            { text: t('contactProcess.processSteps.step4') as string, iconSrc: growthSrc },
          ]} />
        </div>
      </Container>
    </section>
  )
}

export default ContactProcess